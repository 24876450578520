exports.components = {
  "component---src-pages-author-js": () => import("./../../../src/pages/author.js" /* webpackChunkName: "component---src-pages-author-js" */),
  "component---src-pages-england-js": () => import("./../../../src/pages/england.js" /* webpackChunkName: "component---src-pages-england-js" */),
  "component---src-pages-germany-js": () => import("./../../../src/pages/germany.js" /* webpackChunkName: "component---src-pages-germany-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-netherlands-js": () => import("./../../../src/pages/netherlands.js" /* webpackChunkName: "component---src-pages-netherlands-js" */),
  "component---src-pages-norway-js": () => import("./../../../src/pages/norway.js" /* webpackChunkName: "component---src-pages-norway-js" */),
  "component---src-pages-personality-js": () => import("./../../../src/pages/personality.js" /* webpackChunkName: "component---src-pages-personality-js" */),
  "component---src-pages-place-js": () => import("./../../../src/pages/place.js" /* webpackChunkName: "component---src-pages-place-js" */),
  "component---src-pages-poland-js": () => import("./../../../src/pages/poland.js" /* webpackChunkName: "component---src-pages-poland-js" */),
  "component---src-pages-spain-js": () => import("./../../../src/pages/spain.js" /* webpackChunkName: "component---src-pages-spain-js" */)
}

